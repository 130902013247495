import React from "react";

function MetricsCardSysTest(props) {
  const {
    keyID,
    type,
    msg,
    serverName,
    serverLocation,
    cardClickHandler,
    systemType,
    alertWrapperClass,
    lpar,
  } = props;
  return (
    <div
      className={`metricsCard ${type} ${alertWrapperClass || ""}`}
      onClick={cardClickHandler}
      key={keyID}
      style={{ width: "calc(46% - 15px)" }}
    >
      <div className="info">
        <div className="title">
          {serverName}: {true}
          {keyID}
        </div>
        <div className="sub_title">
          {lpar ? serverLocation : `(${systemType})`}
        </div>
      </div>
      <div className="msg">{msg}</div>
    </div>
  );
}

export default MetricsCardSysTest;
