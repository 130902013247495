import React from "react";
import {
  checkPeakSystemStatus,
  metricCalculation,
} from "../../helpers/commonHelper";

function MetricsCard(props) {
  const {
    cpuData,
    keyID,
    type,
    msg,
    metricsName,
    utilization,
    cardClickHandler,
    alertMetricAlignClass,
    alertWrapperClass,
    serverName,
    date,
    alertNotification,
    id,
    metricPage,
    coreMetricPage
  } = props;

  return (
    <div
      className={`metricsCard ${checkPeakSystemStatus(cpuData)} ${
        alertMetricAlignClass || ""
      } ${alertWrapperClass || ""}`}
      onClick={cardClickHandler}
      key={keyID}
      style={{ width: coreMetricPage ?  "calc(31% - 5px)" : ""  }}
    >
      <div className="info">
        <div className="title">
          {cpuData?.dtypedesc}: {true}
          {keyID}
        </div>
        <div className="sub_title">
          {metricCalculation(cpuData, "metricPage")}
        </div>
      </div>
      <div className="msg">{msg}</div>
    </div>
  );
}

export default MetricsCard;
