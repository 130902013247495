import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { handlePrintDetails } from "../../helpers/commonHelper";
import MetricsCard from "../components/MetricsCard";
import SectionHeader from "../components/SectionHeader";
import useQueryData from "../../hooks/useQueryDataHistorical";
import { useDispatch, useSelector } from "react-redux";
import { GridLoader } from "react-spinners";
import {
  fetchAsyncMetricCPUData,
  fetchAsyncMetricDiskData,
  fetchAsyncMetricMemoryData,
  fetchAsyncMetricOtherData,
  getMetricsCPUData,
  getMetricsDiskData,
  getMetricsMemoryData,
  getMetricsOtherData,
} from "../../store/slices/enterpriseServer/metricsSlice";
import { id } from "date-fns/locale";
import MetricsCardTest from "../components/MetricCardTest";
import useQueryDataRealTimeMonitor from "../../hooks/useQueryDataRealTimeMonitor";

function MetricsPage() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const serverId = searchParams.get("sysId");
  const host = searchParams.get("host");
  const serialNo = searchParams.get("serialNo");
  const systemType = searchParams.get("systemType");
  const lparName = searchParams.get("lparName");
  const entityName = searchParams.get("entityName");
  const qd = useQueryData();
  const dispatch = useDispatch();
  const cpuData = useSelector(getMetricsCPUData);
  const diskData = useSelector(getMetricsDiskData);
  const memoryData = useSelector(getMetricsMemoryData);
  const otherData = useSelector(getMetricsOtherData);
  const [arr, setArr] = useState([]);
  const [filteredBreadCrumbsList, setFilteredBreadCrumbsList] = useState([]);
  const [alertSystemData, setAlertSystemData] = useState([]);
  const params = { sysId: serverId, serialNo, systemType, host };
  const realTimeQuery = useQueryDataRealTimeMonitor();

  useEffect(() => {
    // if(!uuid?.loading && uuid.data.uniqueid){
    dispatch(fetchAsyncMetricCPUData({...realTimeQuery, sysid: serverId }));
    dispatch(fetchAsyncMetricDiskData({...realTimeQuery, sysid: serverId }));
    dispatch(fetchAsyncMetricMemoryData({...realTimeQuery, sysid: serverId }));
    dispatch(fetchAsyncMetricOtherData({...realTimeQuery, sysid: serverId }));
    // }
  }, [dispatch]);

  const handleNavigation = (serverName, page) => {
    // myString = myString.replace(/\s+/g, "-");
    const params = {
      sysId: serverId,
      serialNo,
      systemType,
      viewType: serverName,
      host,
      systemName: lparName,
      entityName,
      ...(systemType === "Frame" && { lparName }),
    };
    navigate({
      pathname: `${page}`,
      search: `?${createSearchParams(params)}`,
    });
  };
  let breadCrumbsList = [
    {
      id: 1,
      name: "Enterprise",
      url: "/enterprise-server",
    },
    {
      id: 2,
      name: host,
      ...(systemType === "Frame" && {
        url: `/server?${createSearchParams(params)}`,
      }),
    },
  ];

  const breadCrumbsList2 = [
    {
      id: 3,
      name: serialNo,
      url: ``,
    },
    {
      id: 4,
      name: lparName,
      url: ``,
    },
  ];

  console.log(entityName);

  useEffect(() => {
    // let foundArr = null;
    let filteredBreadCrumbsBySystemType = null;
    // let foundAlertArr = null;
    if (systemType === "Frame") {
      // foundArr = arr2.filter((ele) => ele.lparName === lparName);
      filteredBreadCrumbsBySystemType = [
        ...breadCrumbsList,
        ...breadCrumbsList2,
      ];
      // foundAlertArr = frameAlertArr.filter((ele) => ele.lparName === lparName);
    } else {
      // foundArr = arr2.filter((ele) => ele.lparName === host);
      filteredBreadCrumbsBySystemType = [
        ...breadCrumbsList,
        breadCrumbsList2[0],
      ];
      // foundAlertArr = systemAlertArr.filter((ele) => ele.lparName === host);
    }
    setFilteredBreadCrumbsList(filteredBreadCrumbsBySystemType);
    // setArr(foundArr);
    // setAlertSystemData(foundAlertArr);
  }, [host, serverId, systemType]);

  return (
    <div className="enterprise_wrapper">
      <SectionHeader
        title={"Metrics"}
        btnClickHandler={handlePrintDetails}
        breadCrumbsList={filteredBreadCrumbsList}
      />
      <div className="servers_wrapper-alerts">
        <div className="server_wrapper-alert" style={{ width: "60%" }}>
          <div className="metrics_card_wrapper">
            {cpuData.loading && (
              <div>
                <GridLoader color="#366bd6" />
              </div>
            )}
            {cpuData?.data?.data?.map((item, idx) => {
              if (item.dtype === "cpu_ms" || item.dtype === "cpw") {
                // eslint-disable-next-line array-callback-return
                return;
              } else {
                return (
                  <MetricsCard
                    key={idx}
                    cpuData={item}
                    cardClickHandler={() =>
                      handleNavigation(item.dtype, "/detailed-view-cpu")
                    }
                    {...item}
                  />
                );
              }
            })}
            {diskData.loading && (
              <div>
                <GridLoader color="#366bd6" />
              </div>
            )}
            {otherData?.data?.data?.map((item, idx) => {
              if (item.dtype === "read_write_ratio") {
                // eslint-disable-next-line array-callback-return
                return;
              } else {
                return (
                  <MetricsCard
                    key={idx}
                    cpuData={item}
                    cardClickHandler={() =>
                      handleNavigation(item.dtype, "/detailed-view-other")
                    }
                    {...item}
                  />
                );
              }
            })}
            {diskData?.data?.data?.map((item, idx) => {
              if (item.dtype === "read_write_ratio") {
                // eslint-disable-next-line array-callback-return
                return;
              } else {
                return (
                  <MetricsCard
                    key={idx}
                    cpuData={item}
                    cardClickHandler={() =>
                      handleNavigation(item.dtype, "/detailed-view-disk")
                    }
                    {...item}
                  />
                );
              }
            })}
            {memoryData?.data?.data?.map((item, idx) => {
              if (
                item.dtype === "machine_pool_faulting" ||
                item.dtype === "pool_faulting_rate" ||
                item.dtype === "memory_size_faulting" ||
                item.dtype === "TopPoolFaultingRate"
              ) {
                // eslint-disable-next-line array-callback-return
                return;
              } else {
                return (
                  <MetricsCard
                    key={idx}
                    cpuData={item}
                    cardClickHandler={() =>
                      handleNavigation(item.dtype, "/detailed-view-memory")
                    }
                    {...item}
                  />
                );
              }
            })}
          </div>
        </div>
        <div
          className="server_wrapper-alert scroll-container"
          style={{ width: "36%" }}
        >
          <div className="server_parent server_parent_alert">
            {/* {cpuData.loading && (
              <div>
                <GridLoader color="#366bd6" />
              </div>
            )}
            {!cpuData.loading &&
              cpuData?.data?.data?.map((item, idx) => {
                if (item?.dtype === "cpu_ms" || item?.dtype === "cpw") {
                  // eslint-disable-next-line array-callback-return
                  return;
                } else {
                  return (
                    <MetricsCard
                      key={idx}
                      cpuData={item}
                      // cardClickHandler={() =>
                      //   handleNavigation(item.dtype, "/detailed-view-cpu")
                      // }
                      {...item}
                      alertMetricAlignClass={"alert_metric_alignment"}
                      alertWrapperClass={"metric_alert_wrapper"}
                      alertNotification={true}
                    />
                  );
                }
              })} */}
            <p style={{ textAlign: "center" }}>No alert data for metrics</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MetricsPage;
